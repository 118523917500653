import React from 'react'
import { Header } from 'components/theme'
import { Layout, SEO } from 'components/common'
import Error404 from 'assets/illustrations/404'

export default () => (
	<Layout>
		<Header />
		<SEO title="404: Not found" location="/404" />
		<div style={{textAlign:'center'}}>
			<h1>NOT FOUND</h1>
			<p>We Cannot Locate The Page You Are Trying to Access, please send us
			an email at contact@endslate.co if you were sent a direct link. Thank you!</p>
			<Error404 />
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</div>
	</Layout>
)
